<template>
  <div>
    <div>
      <div class="alignmenu">
        <v-menu offset-y transition="slide-y-transition" open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-badge
                bordered
                bottom
                color="deep-purple accent-4"
                dot
                offset-x="10"
                offset-y="10"
              >
                <v-avatar size="30">
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                  ></v-img>
                </v-avatar>
              </v-badge>
              <div class="settings" style="font-size: 14px; margin-left: 10px">
                NEW USER<br />
                <label style="float: left; font-size: 12px">ID9090
                  
                </label>
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      name: "",
    };
  },
  methods: {
    logout() {
     this.$router.push('/')
    },
  },
  mounted() {
    var name = JSON.parse(localStorage.getItem("username"));
    if (!!name) {
      this.name = name;
    }
  },
};
</script> 

<style>
.alignmenu{
    float: right;
    margin-right: 86px;
    align-items: center !important;
    margin-top: 10px;
}
</style>